import { ActionContext, ActionTree } from 'vuex';
import { Gift } from './gift.interface';
import { Mutations, MutationTypes } from './mutations';
import { State } from './state';

const rewards = {
	kiss: 'gave Steven a kiss 😘',
	thank: 'thanked Steven 🥰',
	dance: 'did a small dance 💃🏻',
	hug: 'gave Steven a hug 🤗',
};
const tmpGifts: string[] = [
	'✅ Moomin socks',
	'✅ Moomin napkins',
	'✅ Moomin book',
	'calendar',
	/*
		1. ✅ Moomin summer
		2. ✅ Moomin winter
		3. ✅ Moomin autumn
		4. Moomin spring
		5. ✅ Dunkin' Vanilla
		6. Roast.nl https://www.roast.nl/product/try-out-box-small/
		7. Bocca https://bocca.nl/product/soulmate/
		8. Bocca https://bocca.nl/product/bttf-blend/
		9. Bocca https://bocca.nl/product/the-blend/
		10. Bocca https://bocca.nl/product/gusto/
		11. Bocca https://bocca.nl/product/easy-rider/
		12. 
	 */
	'✅ 4/12 coffee-subscription',
	'lingerie',
	'blad-cadeau',
	'✅ recipe-book',
	'✅ love-coupon',
];

const gifts: Gift[] = [
	{
		slug: 'socks',
		name: 'a pair of socks',
		description: ``,
		opened: false,
		reward: rewards.dance,
	},
	{
		slug: 'calendar',
		name: 'a personal calendar',
		description: ``,
		opened: false,
		reward: rewards.kiss,
	},
	{
		slug: 'moomin-book',
		name: 'a Moomin book',
		description: ``,
		opened: false,
		reward: rewards.hug,
	},
	{
		slug: 'recipe-book',
		name: 'a recipe book you wanted',
		description: ``,
		opened: false,
		reward: rewards.thank,
	},
	{
		slug: 'moomin-napkins',
		name: 'cute napkins',
		description: ``,
		opened: false,
		reward: rewards.thank,
	},
	{
		slug: 'coffee-subscription',
		name: 'a coffee subscription',
		description: ``,
		opened: false,
		reward: rewards.hug,
	},
	{
		slug: 'love-coupon',
		name: 'a love-coupon',
		description: ``,
		opened: false,
		reward: rewards.kiss,
	},
	{
		slug: 'tennis-lessons',
		name: 'private tennis lessons',
		description: ``,
		opened: false,
		reward: rewards.hug,
	},
];

export enum ActionTypes {
	INIT_GIFTS = 'INIT_GIFTS',
	RESET_GIFTS = 'RESET_GIFTS',
}

type AugmentedActionContext = {
	commit<K extends keyof Mutations>(
		key: K,
		payload: Parameters<Mutations[K]>[1],
	): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, State>, 'commit'>;

export interface Actions {
	[ActionTypes.INIT_GIFTS]({ commit }: AugmentedActionContext): Promise<void>;
	[ActionTypes.RESET_GIFTS]({ commit }: AugmentedActionContext): Promise<void>;
}

export const actions: ActionTree<State, State> & Actions = {
	[ActionTypes.INIT_GIFTS]({ commit }) {
		return new Promise((resolve) => {
			const store = sessionStorage.getItem('gifts');
			commit(MutationTypes.SET, store ? JSON.parse(store).gifts : gifts);
			commit(
				MutationTypes.SET_CURRENT,
				store ? JSON.parse(store).currentGift : '',
			);
			resolve();
		});
	},
	[ActionTypes.RESET_GIFTS]({ commit }) {
		return new Promise((resolve) => {
			sessionStorage.removeItem('gifts');
			commit(MutationTypes.SET, gifts);
			commit(MutationTypes.SET_CURRENT, '');
			resolve();
		});
	},
};
