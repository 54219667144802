
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'app',
	data() {
		return {
			transitionName: 'none',
		};
	},
	watch: {
		$route(to, from) {
			if (!from.name || from.name === to.name) {
				this.transitionName = 'none';
			} else if (from.name === 'Home') {
				this.transitionName = 'slide-to-top';
			} else if (to.name === 'Home') {
				this.transitionName = 'slide-from-top';
			} else {
				this.transitionName = 'none';
			}
		},
	},
});
