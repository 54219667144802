import { Gift } from './gift.interface';

export interface State {
	gifts: Gift[];
	currentGift: string | undefined;
}

export const state: State = {
	gifts: [],
	currentGift: undefined,
};
