import tippy from 'tippy.js';
import { Directive, DirectiveBinding } from 'vue';

export const tooltip: Directive = {
	mounted: (element: HTMLElement, binding: DirectiveBinding) => {
		tippy(element, {
			content: binding.value,
		});
	},
};
