import { MutationTree } from 'vuex';
import { Gift } from './gift.interface';
import { State } from './state';

export enum MutationTypes {
	SET = 'SET',
	SET_CURRENT = 'SET_CURRENT',
	OPEN = 'OPEN',
}

export type Mutations<S = State> = {
	[MutationTypes.SET](state: S, payload: Gift[]): void;
	[MutationTypes.SET_CURRENT](state: S, payload: string): void;
	[MutationTypes.OPEN](state: S, payload: string): void;
};

export const mutations: MutationTree<State> & Mutations = {
	[MutationTypes.SET](state, payload: Gift[]) {
		state.gifts = payload;
		saveState(state);
	},
	[MutationTypes.SET_CURRENT](state, payload: string) {
		state.currentGift = payload;
		saveState(state);
	},
	[MutationTypes.OPEN](state, payload: string) {
		state.gifts = state.gifts.map((gift: Gift) => {
			if (gift.slug === payload) {
				gift.opened = true;
			}
			return gift;
		});
		saveState(state);
	},
};

function saveState(state: State): void {
	sessionStorage.setItem('gifts', JSON.stringify(state));
}
