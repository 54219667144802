
import { useStore } from '@/store/store';
import { ActionTypes } from '@/store/gifts/actions';
import { defineComponent } from 'vue';
// https://github.com/catdad/canvas-confetti
import confetti from 'canvas-confetti';
import { MutationTypes } from '@/store/gifts/mutations';
import { Gift } from '@/store/gifts/gift.interface';

export default defineComponent({
	name: 'Home',
	store: useStore(),
	data() {
		return {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			customConfetti: null as any | null,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			randomConfettiTimeout: null as any | null,
		};
	},
	computed: {
		opened(): Gift[] {
			return this.$store.getters.openedGifts;
		},
		amountOpened(): number {
			return (
				this.$store.state.gifts.length -
				this.$store.getters.unopenedGifts.length
			);
		},
		allOpened(): boolean {
			return this.$store.getters.unopenedGifts.length === 0;
		},
	},
	created() {
		this.$store.dispatch(ActionTypes.INIT_GIFTS);

		setTimeout(() => {
			const confettiCanvas: HTMLCanvasElement = document.getElementById(
				'confetti',
			) as HTMLCanvasElement;
			this.customConfetti = confetti.create(confettiCanvas, {
				resize: true,
				useWorker: true,
			});
			this.randomConfetti();

			if (confettiCanvas) {
				document.onclick = (event) => {
					this.popConfetti(
						(1 / confettiCanvas.width) * event.x,
						(1 / confettiCanvas.height) * event.y,
					);
				};
			}
		});
	},
	unmounted(): void {
		clearTimeout(this.randomConfettiTimeout);
		this.customConfetti.reset();
	},
	methods: {
		randomConfetti(): void {
			this.popConfetti(Math.random(), Math.random());
			this.randomConfettiTimeout = setTimeout(() => {
				requestAnimationFrame(() => {
					this.randomConfetti();
				});
			}, 500 + Math.random() * 1000);
		},
		popConfetti(x: number, y: number): void {
			this.customConfetti({
				particleCount: 100,
				spread: 360,
				startVelocity: 20,
				origin: {
					x: x,
					y: y,
				},
			});
		},
		openGift(gift?: string): void {
			this.$store.commit(
				MutationTypes.SET_CURRENT,
				gift || this.$store.getters.unopenedGifts[0].slug,
			);
		},
		reset(): void {
			this.$store.dispatch(ActionTypes.RESET_GIFTS);
		},
	},
});
