import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import { key, store } from './store/store';
// import { firebase } from './services/firebase.service';
import './style/index.css';
import { tooltip } from './directives/tooltip';

createApp(App)
	.use(store, key)
	.use(router)
	// .use(firebase)
	.directive('tooltip', tooltip)
	.mount('#app');
