import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue';

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		meta: {
			title: '❤️ Happy Birthday! ❤️',
		},
	},
	{
		path: '/gift',
		name: 'Gift',
		component: () => import(/* webpackChunkName: "gift" */ '../views/Gift.vue'),
		meta: {
			title: '❤️ Happy Birthday! ❤️',
		},
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior() {
		return { top: 0, left: 0 };
	},
});

const defaultTitle = document.title;
router.beforeEach((to) => {
	document.title = (to.meta.title as string) || defaultTitle;
});

export default router;
