import { GetterTree } from 'vuex';
import { Gift } from './gift.interface';
import { State } from './state';

export type Getters = {
	openedGifts(state: State): Gift[];
	unopenedGifts(state: State): Gift[];
	currentGift(state: State): Gift | undefined;
};

export const getters: GetterTree<State, State> & Getters = {
	openedGifts: (state: State): Gift[] => {
		return state.gifts.filter((recipe: Gift) => recipe.opened);
	},
	unopenedGifts: (state: State): Gift[] => {
		const gifts = state.gifts.filter((recipe: Gift) => !recipe.opened);
		// Random shuffle the gifts
		for (let i = gifts.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			const temp = gifts[i];
			gifts[i] = gifts[j];
			gifts[j] = temp;
		}
		return gifts;
	},
	currentGift: (state: State): Gift | undefined => {
		return state.gifts.find(
			(recipe: Gift) => recipe.slug === state.currentGift,
		);
	},
};
